import React from "react";
import HeaterList from "../components/heater-list"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero"
import { Link } from "gatsby"

export default function Electric({ data, pageContext }) {
  return (
    <Layout>
    
      <SEO
        title="Gas Patio Heaters"
        keywords={[`gas patio heater`, `outdoor gas heaters`, `gas garden heater`]}
        description="Keep warm outdoors with a gas patio heater. Our guide helps you find the best option, and we list the best deals too!"
      />

<Hero heading='Gas Patio Heaters' subheading='Instant, portable heat' />

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">The best gas patio heaters.</h3>
<p className="text-gray-600">We've hand-picked the top patio heaters on the market for you for explore.<br /><br /></p>
<HeaterList data= {data.products} />
</div>
</div>

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">Why choose gas?</h3>
<p className="text-gray-600 mb-2">When it comes down to heat outdoors, nothing is as quick and mobile as a gas heater. Instant heat from the push of a button, and no cable restricting your movement.</p>
<p className="text-gray-600 mb-2">The main benefits of a gas patio heater are:</p>
<p className="text-gray-600 mb-2"><strong>Fuel source</strong> - Gas is portable anywhere so you can be 'off the grid' - the heater can be placed at the furthest corner of your garden.</p>
<p className="text-gray-600 mb-2"><strong>Quick</strong> - Electric heaters use bulbs that can warm up very fast, and turn off quickly too.</p>
<p className="text-gray-600 mb-4"><strong>Direct</strong> - Electric heaters use infrared light, so it can be directed where you want it, and it doesn't lose much heat to the surrounding air.</p>
<p className="text-gray-600 mb-2">There are drawbacks - you'll need to purchase a gas bottle to go with your heater, and monitor the level so that you can replace it when it's low - also the heat is often dissipates into the air before reaching your skin. If you want to consider alternatives, check out our <Link to="/electric-heaters/">electric heaters</Link>.</p>
</div>
</div>

    </Layout>
  );
}

export const query = graphql`
  query {
    products: allGoogleSheetProductsRow(filter: {powersource: {eq: "Gas"}}) {
      edges {
        node {
          productImage
          productName
        }
      }
    }
  }
`